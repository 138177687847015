@media print {
	html,
	body {
		width: 210mm;
		height: 297mm;
	}
	.invoice-page {
		-webkit-print-color-adjust: exact;
	}
	.col-sm-1,
	.col-sm-2,
	.col-sm-3,
	.col-sm-4,
	.col-sm-5,
	.col-sm-6,
	.col-sm-7,
	.col-sm-8,
	.col-sm-9,
	.col-sm-10,
	.col-sm-11,
	.col-sm-12 {
		float: left;
		padding: 0;
	}
	.col-sm-12 {
		width: 100%;
	}
	.col-sm-11 {
		width: 91.66666667%;
	}
	.col-sm-10 {
		width: 83.33333333%;
	}
	.col-sm-9 {
		width: 75%;
	}
	.col-sm-8 {
		width: 66.66666667%;
	}
	.col-sm-7 {
		width: 58.33333333%;
	}
	.col-sm-6 {
		width: 50%;
	}
	.col-sm-5 {
		width: 41.66666667%;
	}
	.col-sm-4 {
		width: 33.33333333%;
	}
	.col-sm-3 {
		width: 25%;
	}
	.col-sm-2 {
		width: 16.66666667%;
	}
	.col-sm-1 {
		width: 8.33333333%;
	}
	div[data-size="A4"] {
		margin: 0;
		box-shadow: 0;
		padding: 3em 5em !important;
	}
	.breadcrumb,
	.subheader {
		display: none;
	}
	*:not(.keep-print-font) {
		font-family: Arial, Helvetica, sans-serif !important;
		font-size: 11pt !important;
	}
	table {
		font-size: 100% !important;
	}
}

@page {
	size: auto;
	margin: 0;
}

div[data-size="A4"] {
	background: white;
	display: block;
	margin: 0 auto;
	margin-bottom: 0.5cm;
	box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
	background: url(../img/svg/pattern-1.svg) no-repeat center bottom;
	background-size: cover;
	padding: 4rem;
	position: relative;
}

@media only screen and ( max-width: 992px ){
	div[data-size="A4"],
	.container {
		padding: 0;
		box-shadow: none;
	}
}